<template>
  <div>
      <BookC/>
  </div>
</template>

<script>
import BookC from '../components/BookC.vue'
export default {
  components: { BookC },

}
</script>

<style>

</style>