import { render, staticRenderFns } from "./SumaryC.vue?vue&type=template&id=7185c65a&"
import script from "./SumaryC.vue?vue&type=script&lang=js&"
export * from "./SumaryC.vue?vue&type=script&lang=js&"
import style0 from "./SumaryC.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports