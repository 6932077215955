<template>
  <div class="bookc">
    <div class="booksections">
      <!-- Resumen -->
      <div class="bookresume">
        <div style="flex:1;">
          <SumaryC v-bind:totalPrice="totalPrice" />
        </div>
      </div>
      <!-- Sections -->
      <div class="booksect">
        <div class="superiorsect">
          <!-- Superior -->
          <div class="dates">
            <!--XXX <div>
              <p>XXXHuespedes</p>
            </div>XXX -->
              <div class="datepickerc">
                <p class="parag">{{ $t("book.resume.in") }}</p>
                <b-form-datepicker
                  id="datepicker"
                  v-model="checkin"
                  :locale="this.$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  :min="min"
                  :date-disabled-fn="disableDates"
                  class="dp2"
                  placeholder=" "
                ></b-form-datepicker>
              </div>
              <div class="datepickerc">
                <p class="parag">{{ $t("book.resume.out") }}</p>
                <b-form-datepicker
                  id="datepicker2"
                  v-model="checkout"
                  :locale="this.$i18n.locale"
                  :date-format-options="{
                   day: '2-digit',
                    month: '2-digit',
                     year: 'numeric',
                    
                  }"
                  :min="checkin||min"
                  :date-disabled-fn="disableDates"
                  class="dp2"
                  placeholder=" "
                ></b-form-datepicker>
              </div>
            <div class="btnselect">
              <b-button
                v-on:click="addCheckIn(checkin), addCheckOut(checkout), addDays(checkout,checkin)"
                id="book"
                class="selectbtn"
                >{{ $t("menu.select") }}</b-button
              >
            </div>
          </div>
          <!--  -->
        </div>
        <!-- Progreso -->
        <div class="progresssect">
          <h3 style="">{{ $t("rooms.title") }}</h3>
          <div class="">
            <RoomProductsC />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-for="items in topRated" :key="items.id">
      <img :src="items.url" alt="" />
      <h5>{{ items.name }}</h5>
    </div> -->
  </div>
</template>

<script>
import SumaryC from "@/components/Rooms/SumaryC.vue";
import RoomProductsC from "@/components/Rooms/RoomProductsC.vue";
export default {
  name: "BookC",
  components: {
    SumaryC,
    RoomProductsC,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    // minDate.setMonth(e.getMonth())
    // minDate.setDate()
    return {
      min: minDate,
      checkin: "",
      checkout: "",
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    totalPrice() {
      let price = 0;
      this.$store.state.cartItems.map((el) => {
        price += el["quantity"] * el["price"] * this.$store.state.days;
      });
      return price ;
    },
    // days() {
    //   return this.$store.state.days;
    // },
  },
  methods: {
    addCheckIn(checkin) {
      //   this.$store.commit("addToCart")
      this.$store.dispatch("addToCheckIn", checkin);
    },
    addCheckOut(checkout) {
      //   this.$store.commit("addToCart")
      this.$store.dispatch("addToCheckOut", checkout);
    },
    // addDays(checkout){
    //   var fecha1 = new Date(checkout);
    //   const dia = (fecha1.getDate()+1)
    //   this.$store.dispatch("addToDays",dia);
    // }
  // Calculo de días
    addDays(checkout,checkin){
      var fecha1 = new Date(checkout);
      var fecha2 = new Date(checkin);
      let milisegDia = 24 * 60 * 60 * 1000;
      let milisegTransc = Math.abs(fecha1.getTime() - fecha2.getTime());
      const diasTransc = Math.round(milisegTransc/milisegDia);

      this.$store.dispatch("addToDays",diasTransc);
    },
    // Fechas deshabilitadas
    disableDates(date) {
      // Asegurarse de que el parámetro sea un objeto Date
      const currentDate = new Date(date);
      // const year = currentDate.getFullYear();
      const month = currentDate.getMonth();  // Enero es 0, Febrero es 1
      const day = currentDate.getDate();

      // Deshabilitar del 1 al 15 de febrero
      if (month === 1 && day >= 1 && day <= 15) {
        return true;  // Deshabilitar estas fechas
      }
      return false;
    }
  },
};
</script>

<style>
.bookc {
  margin: 60px 20px 20px 20px;
}
#datepicker {
  color: #533e2d;
}
#datepicker2 {
  color: #533e2d;
}
.booksections {
  display: flex;
  flex: 1;
}
.booksect {
  display: flex;
  flex-direction: column;
}
.superiorsect {
  display: flex;
  border-color: #533e2d;
  border-style: double;
}
.progresssect {
  display: flex;
  flex-direction: column;
}
.dates{
  display: flex;
  flex: 1;
  padding: 5px 10px 10px 10px;
  /* justify-items: center; */
  /* align-items: center; */
}
.datepickerc{
  margin: 0 10px;
  flex: 1;
}
.parag{
  margin: 0px; 
  padding: 0px;
  flex: 1;
}
.btnselect{
  display: flex;
  flex: 1;
  justify-content: center;
}
.selectbtn{
  padding: 0.5rem 30%;
  margin: 0 10px;
}

@media (min-width: 992px) {
  .booksections {
    flex-direction: row;
  }
  .superiorsect {
    margin-bottom: 10px;
  }
  .bookresume {
    display: flex;
    flex: 2;
  }
  .booksect {
    display: flex;
    flex: 4;
    margin-left: 15px;
  }
  .dates{
    align-items: center; 
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .booksections {
    flex-direction: row;
  }
  .superiorsect {
    margin-bottom: 10px;
  }
  .bookresume {
    display: flex;
    flex: 2;
  }
  .booksect {
    display: flex;
    flex: 4;
    margin-left: 15px;
  }
  .dates{
    align-items: center; 
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .booksections {
    flex-direction: column;
  }
  .superiorsect {
    margin-bottom: 10px;
  }
  .bookresume {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    justify-content:center;
  }
  .booksect {
    display: flex;
    flex: 1;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .booksections {
    flex-direction: column;
  }
  .superiorsect {
    margin-bottom: 10px;
  }
  .bookresume {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    justify-content:center;
  }
  .booksect {
    display: flex;
    flex: 1;
  }
  .dates{
    flex-direction: column;
  }
  .btnselect{
    margin-top: 10px;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .booksections {
    flex-direction: column;
    flex:1;
  }
  .superiorsect {
    margin-bottom: 10px;
    flex: 1;
  }
  .bookresume {
    display: flex;
    flex: 1;
    margin-bottom: 15px;
    justify-content:center;
  }
  .booksect {
    display: flex;
    flex: 1;
  }
  .dates{
    flex-direction: column;
    flex: 1;
  }
  .dp2{
    flex:1;
  }
  .btnselect{
    margin-top: 10px;
  }
}
</style>