<template>
  <div class="sumary">
    <!-- Encabezado -->
    <div>
      <h2 style="text-align: center">
        {{ $t("book.resume.title") }}
      </h2>
      <div class="encabezado">
        <div class="encin">
          <p class="encres">{{ $t("book.resume.in") }}</p>
          <p class="ench">{{ checkin }}</p>
          <p class="encres">{{ $t("book.resume.after") }}</p>
        </div>
        <div class="encout">
          <p class="encres">{{ $t("book.resume.out") }}</p>
          <p class="ench">{{ checkout }}</p>
          <p class="encres">{{ $t("book.resume.before") }}</p>
        </div>
      </div>
    </div>
    <!-- Contenido -->
    <div class="sectionsum">
      <!-- Fechas -->
      <div class="">
        <p style="margin-bottom: 5px" id="selection">
          {{ $t("book.resume.select") }}
        </p>
      </div>
      <!-- Products -->
      <div class="sumprod">
        <div v-for="items in cartItems" :key="items.id">
          <div class="spitems">
            <div style="flex-direction: column">
              <p class="sptitle">{{ $t("rooms." + items.name + ".title") }}</p>
              <p class="spquantity">
                {{ $t("book.resume.quantity") }} {{ items.quantity }}
              </p>
            </div>
            <div style="flex-direction: column">
              <p class="sptitle">
                {{ items.price }} USD$/{{ $t("rooms.price") }}
              </p>
              <p class="spquantity">
                {{ items.price * items.quantity }} USD$/{{ $t("rooms.price") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Total -->
    <div class="sectionsum2">
      <div class="stotal">
        <div class="stt">
          <p class="">{{ $t("book.night") }}:</p>
        </div>
        <div class="sttp">
          <p style="text-align: right; margin-bottom: 0px; font-weight: bold">
            {{ days }} {{ $t("book.night") }}
          </p>
        </div>
      </div>
      <div class="stotal">
        <div class="stt">
          <p class="">Total:</p>
        </div>
        <div class="sttp">
          <p style="text-align: right; margin-bottom: 0px; font-weight: bold">
            {{ totalPrice }} USD$
          </p>
          <p class="totalinclude">{{ $t("book.include") }}</p>
        </div>
      </div>
      <div class="stbtn">
        <b-button id="book" :to="{ name: 'CheckOut' }">{{
          $t("book.continue")
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SumaryC",
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    checkin() {
      return this.$store.state.checkin;
    },
    checkout() {
      return this.$store.state.checkout;
    },
    days() {
      return this.$store.state.days;
    },
  },
  props: ["totalPrice"],
};
</script>

<style>
#selection {
  font-family: "Aladin", cursive;
}
.sumary {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-color: #533e2d;
  border-style: double;
  padding: 5px 15px;
  background-color: #fffbe8;
}
.encabezado {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  justify-content: space-between;
}
.encin {
  margin: 0 30px 0 0;
}
.encout {
  margin: 0 0px 0 0px;
}
.ench,
.encres {
  margin: 0px;
  padding: 0px;
}
.ench {
  font-weight: bold;
}
.sectionsum {
  border-color: #533e2d;
  border-top-style: groove;
  padding-top: 9px;
}
.sumprod {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.spitems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sptitle {
  margin-bottom: 0px;
}
.sectionsum2 {
  border-color: #ddca7d;
  border-top-style: groove;
  padding-top: 9px;
}
.stotal {
  display: flex;
  flex-direction: row;
}
.totalinclude{
  text-align: right;
}
.stt {
  flex: 2;
}
.sttp {
  flex: 3;
}
.stbtn {
  flex: 1;
}

@media (min-width: 992px) {
  .sumary {
    flex: 1;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .sumary {
    flex: 1;
  }
  .totalinclude{
    font-size: 70%;
  }
}
@media (min-width: 600px) and (max-width: 799px) {
  .sumary {
    flex: 1;
  }
  .totalinclude{
    font-size: 100%;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  .sumary {
    flex: 1;
  }
  .totalinclude{
    font-size: 90%;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .sumary {
    flex: 1;
  }
  .totalinclude{
    font-size: 90%;
  }
}
</style>