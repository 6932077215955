<template>
  <div class="productssect">
    <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p> -->

    <div v-for="items in topRated" :key="items.id">
      <b-card no-body class="bcardbpc">
        <b-row no-gutters>
          <b-col md="6" class="imgcol">
            <b-card-img
              :src="items.url"
              alt="Room Image"
              class="imgcardrpc"
            ></b-card-img>
          </b-col>
          <b-col md="">
            <b-card-body>
              <b-card-text class="">
                <div class="">
                  <h4>{{ $t("rooms." + items.name + ".title") }}</h4>
                  <p class="text-justify mb-1">
                    {{ $t("rooms." + items.name + ".description") }}{{ $t("rooms.compl") }}
                  </p>
                </div>
                <div class="">
                  <div class="cardtext2">
                    <div class="">
                      <p>{{ $t("book.rate") }} / {{ $t("book.price") }}</p>
                    </div>
                    <div class="text-right">
                      <p style="margin-bottom: 0px">{{ items.price }} USD$</p>
                      <!-- <p class="mb-0">{{ $t("book.price") }}</p> -->
                      <p class="">{{ $t("book.include") }}</p>
                    </div>
                  </div>
                  <div class="text-right">
                    <b-link
                      v-on:click="removeItem(items)"
                      class="mr-2 text-sm text-goldenBrown"
                      id="linkdelete"
                      >Eliminar</b-link
                    >
                    <b-button v-on:click="addItem(items)" id="book">
                      {{ $t("menu.book") }}
                    </b-button>
                  </div>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <!-- <b-card
        :img-src="items.url"
        img-alt="Card image"
        img-left
        class="mb-3 overflow-hidden"
      >
        <b-card-text>
          <h5>{{$t("rooms."+items.name+".title")}}</h5>
        </b-card-text>
      </b-card> -->
      <!-- <img :src="items.url" alt="">
          <h5>{{$t("rooms."+items.name+".title")}}</h5> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomProductsC",
  computed: {
    topRated() {
      return this.$store.state.topRated;
    },
  },
  methods: {
    addItem(items) {
      //   this.$store.commit("addToCart")
      this.$store.dispatch("addToCart", items);
    },
    removeItem(items) {
      this.$store.dispatch("removeItem", items);
    },
  },
};
</script>

<style>
#linkdelete {
  color: #b88b4a;
}
.productssect{
  border-color: #533e2d;
  border-style: double;
  padding: 1%;
}
.bcardbpc{
  display: flex;
  margin: 10px 0;
}
.cardtext2{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }
@media (min-width: 992px) {
  
}
@media (min-width: 800px) and (max-width: 991px) {
/* .imgcol{
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.imgcardrpc{
    height:100%;
    width: auto;
} */
  
}
@media (min-width: 600px) and (max-width: 799px) {
  
}
@media (min-width: 480px) and (max-width: 599px) {
  
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  
}
</style>