var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bookc"},[_c('div',{staticClass:"booksections"},[_c('div',{staticClass:"bookresume"},[_c('div',{staticStyle:{"flex":"1"}},[_c('SumaryC',{attrs:{"totalPrice":_vm.totalPrice}})],1)]),_c('div',{staticClass:"booksect"},[_c('div',{staticClass:"superiorsect"},[_c('div',{staticClass:"dates"},[_c('div',{staticClass:"datepickerc"},[_c('p',{staticClass:"parag"},[_vm._v(_vm._s(_vm.$t("book.resume.in")))]),_c('b-form-datepicker',{staticClass:"dp2",attrs:{"id":"datepicker","locale":this.$i18n.locale,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },"min":_vm.min,"date-disabled-fn":_vm.disableDates,"placeholder":" "},model:{value:(_vm.checkin),callback:function ($$v) {_vm.checkin=$$v},expression:"checkin"}})],1),_c('div',{staticClass:"datepickerc"},[_c('p',{staticClass:"parag"},[_vm._v(_vm._s(_vm.$t("book.resume.out")))]),_c('b-form-datepicker',{staticClass:"dp2",attrs:{"id":"datepicker2","locale":this.$i18n.locale,"date-format-options":{
                 day: '2-digit',
                  month: '2-digit',
                   year: 'numeric',
                  
                },"min":_vm.checkin||_vm.min,"date-disabled-fn":_vm.disableDates,"placeholder":" "},model:{value:(_vm.checkout),callback:function ($$v) {_vm.checkout=$$v},expression:"checkout"}})],1),_c('div',{staticClass:"btnselect"},[_c('b-button',{staticClass:"selectbtn",attrs:{"id":"book"},on:{"click":function($event){_vm.addCheckIn(_vm.checkin), _vm.addCheckOut(_vm.checkout), _vm.addDays(_vm.checkout,_vm.checkin)}}},[_vm._v(_vm._s(_vm.$t("menu.select")))])],1)])]),_c('div',{staticClass:"progresssect"},[_c('h3',{},[_vm._v(_vm._s(_vm.$t("rooms.title")))]),_c('div',{},[_c('RoomProductsC')],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }